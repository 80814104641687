<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>应急计划管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有应急计划信息" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="emergencyPlanManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增应急计划信息" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addItem"
                :model="newItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="计划时间：" prop="op_date">
                  <input
                    type="date"
                    placeholder=""
                    style="width: 200px"
                    v-model="newItem.op_date"
                  />
                </FormItem>
                <FormItem label="演练科目：" prop="name">
                  <Input v-model="newItem.name" placeholder="" />
                </FormItem>
                <FormItem label="演练类别：" prop="catagory">
                  <Input v-model="newItem.catagory" placeholder="" />
                </FormItem>
                <FormItem label="演练方式：" prop="op_way">
                  <Input v-model="newItem.op_way" placeholder="" />
                </FormItem>
                <FormItem label="演练级别：" prop="glevel">
                  <Input v-model="newItem.glevel" placeholder="" />
                </FormItem>
                <FormItem label="地点：" prop="position">
                  <Input v-model="newItem.position" placeholder="" />
                </FormItem>
                <FormItem label="组织单位：" prop="leader_com">
                  <Input v-model="newItem.leader_com" placeholder="" />
                </FormItem>
                <FormItem label="配合单位：" prop="other_com">
                  <Input v-model="newItem.other_com" placeholder="" />
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input v-model="newItem.remark" placeholder=""/>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addItem')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addItem')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含应急计划相关数据的excel文件：
                  <a href="/public_files/templates/EmergencyPlanTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                :iurl="post_url_tail"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>应急计划信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterItem"
                :model="currentItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="计划时间：" prop="op_date">
                  <input
                    type="date"
                    placeholder=""
                    style="width: 200px"
                    v-model="currentItem.op_date"
                  />
                </FormItem>
                <FormItem label="演练科目：" prop="name">
                  <Input v-model="currentItem.name" placeholder="" />
                </FormItem>
                <FormItem label="演练类别：" prop="catagory">
                  <Input v-model="currentItem.catagory" placeholder="" />
                </FormItem>
                <FormItem label="演练方式：" prop="op_way">
                  <Input v-model="currentItem.op_way" placeholder="" />
                </FormItem>
                <FormItem label="演练级别：" prop="glevel">
                  <Input v-model="currentItem.glevel" placeholder="" />
                </FormItem>
                <FormItem label="地点：" prop="position">
                  <Input v-model="currentItem.position" placeholder="" />
                </FormItem>
                <FormItem label="组织单位：" prop="leader_com">
                  <Input v-model="currentItem.leader_com" placeholder="" />
                </FormItem>
                <FormItem label="配合单位：" prop="other_com">
                  <Input v-model="currentItem.other_com" placeholder="" />
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input v-model="currentItem.remark" placeholder=""/>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterItem')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function itemFac() {
  return {
    id: 0,
    op_date:null,
    name:"",
    catagory:"",
    op_way:"",
    glevel:"",
    position:"",
    leader_com:"",
    other_com:"",
    remark:""
  };
}

export default {
  name: "emergencyPlanManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      title: "应急计划",
      post_url_tail: "/emergency_plan_alter",
      update_count: 0,
      loadingStatus: false,
      newItem: itemFac(),
      currentItem: itemFac(),
      scModal: false,
      columnsFormatData: [
        // {
        //   title: "线路",
        //   key: "line_name",
        //   align: "center",
        //   width: 75,
        // },
        {
          title: "演练科目",
          key: "name",
          align: "center",
          width: 150,
        },
        {
          title: "组织单位",
          key: "leader_com",
          tooltip: "true",
          align: "center",
        },
        {
          title: "地点",
          key: "position",
          tooltip: "true",
          align: "center",
          width: 130,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      ruleValidate: {
        leader_com: [
          {
            required: true,
            type: "string",
            message: "请输入组织单位名称！",
            trigger: "blur",
          },
        ],
        glevel: [
          {
            required: true,
            type: "string",
            message: "请输入级别！",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            type: "string",
            message: "请输入演练科目！",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            type: "string",
            message: "请输入位置！",
            trigger: "blur",
          },
        ],
        res_area: [
          {
            required: true,
            type: "string",
            message: "请输入覆盖范围！",
            trigger: "blur",
          },
        ],
        telephone: [
          {
            required: true,
            type: "string",
            message: "请输入值班电话！",
            trigger: "blur",
          },
        ],
      },
      option: {},
      activeOption: {},
    };
  },
  watch: {},
  computed: {},
  created() {
    // this.getStaticOption();
    // this.getActiveOption();
  },
  methods: {
    // getStaticOption() {
    //   this.$axios
    //     .post(this.$url + "/static_option", {
    //       userToken: userToken,
    //       askData: "resultRiskManage",
    //     })
    //     .then((response) => {
    //         this.option = response.data.res_record;
    //     });
    // },
    // getActiveOption() {
    //   this.$axios
    //     .post(this.$url + "/active_option", {
    //       userToken: userToken,
    //       askData: "lineList",
    //     })
    //     .then((response) => {
    //       this.activeOption = { lineChoices: response.data.res_record };
    //     });
    // },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addItem");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterItem");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条" + this.title + "信息：",
        content:
          "<p>" +
          "演练科目：" +
          value.name +
          "</p>" +
          "<p>" +
          "组织单位：" +
          value.leader_com +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + this.post_url_tail, {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      this.currentItem = JSON.parse(JSON.stringify(value));
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>