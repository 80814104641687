<template>
    <div>
        <div class="excelform-wrapper">
            <Upload ref="upload" :before-upload="handleUpload" :on-success="handleSuccess" :action="post_url">
                <Button class="button-wrapper" icon="ios-cloud-upload-outline">请选择上传文件</Button>
            </Upload>
            <div v-if="file !== null" class="file-space-wrapper">
                <div class="file-space">
                    <div class="file-icon-wrapper">
                        <Icon type="ios-document-outline" :size="50" />
                    </div>
                    <div class="fp-inner">
                        <p>{{ file.name }}</p>
                        <p>{{file.size/1024+"K"}}</p>
                    </div>
                    <div class="del-icon-wrapper"><Button class="del-button" @click="handleRemove" type="text" icon="ios-trash"
                            size="large"></Button></div>
                </div>
                <div class="button-wrapper">
                    <input type="checkbox" v-model="ifDelData" class="check-st"/><span>是否删除原有数据</span>
                </div>
                <div class="button-wrapper">
                    <Button @click="upload" :loading="loadingStatus" long size="large">
                        {{loadingStatus ? 'Uploading' : '确认提交' }}
                    </Button>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
    let userToken = localStorage.getItem("current_user_token");
    
    export default {
        name: "fileUpload",
        data() {
            return {
                file: null,
                loadingStatus: false,
                ifDelData: false

            }
        },
        props: {
            target: {
                type: String,
                required: true
            },
            iurl:{
                type:String,
                default:'/excel_upload'
            }
        },
        methods: {
            handleRemove() {
                this.file = null;
            },
            handleSuccess() {

            },
            handleUpload(file) {
                if (file.name.split('.').pop() !== "xlsx") {
                    this.$Notice.warning({
                        title: '文件格式不符',
                        desc: '文件： ' + file.name + ' 的格式不正确，请选择后缀为“.xlsx”的文件!'
                    });
                }
                else if (file.size > 20480000) {
                    this.$Notice.warning({
                        title: '文件太大',
                        desc: '文件：  ' + file.name + ' 的大小超出限制, 不能大于20MB!'
                    });
                } else {
                    this.file = file;
                }
                return false

            },
            upload() {
                let fileObj = this.file;
                let form = new FormData();
                form.append("file", fileObj);
                form.append('userToken', userToken);
                form.append('target_name', this.target);
                form.append('askData', this.target);
                form.append('ifDelData', this.ifDelData);
                this.loadingStatus = true;
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                this.$axios.post(
                    this.$url + this.iurl,
                    form,
                    config
                ).then(
                    response => {
                        this.file = null;
                        this.loadingStatus = false;
                        if (response.data === 'upload_success'||response.data.stat==='ok') {
                            this.$Message.success('文件上传成功！');
                            this.$emit('handleSuccess');
                        } else {
                            this.$Message.info(response.data)
                        }
                    }
                ).catch(
                    error => {
                        this.file = null;
                        this.loadingStatus = false;
                        this.$Message.info(error+'未知错误！');
                    }
                );
                /**setTimeout(() => {
                    this.file = null;
                    this.loadingStatus = false;
                    this.$Message.success('文件上传成功！')
                }, 1500);**/
            }

        },
        computed: {
            post_url: function () {
                return "/uploadfilebyexcel/" + this.target

            }
        },
    }
</script>
<style scoped>
    .excelform-wrapper {
        text-align: center;
        padding: 30px;
        min-height: 600px;
        font-size: 14px;
    }

    .file-space {
        width: 400px;
        margin: 0 auto;
        background-color: #E3EAF4;
        padding: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        overflow: hidden;
    }

    .file-icon-wrapper {
        float: left;
        width: 40px;
    }

    .del-icon-wrapper {
        float: right;
        width: 40px;
    }

    .fp-inner {
        float: left;
        padding: 10px 20px;
        text-align: left;
    }

    .del-button:hover {
        background-color: #E3EAF4;
    }

    .button-wrapper {
        width: 400px;
        margin: 20px auto;
        font-size: 14px;
    }

    .check-st {
        outline: none;
        width: 16px;
        height: 16px;
        background-color: #aaa;
        position: relative;
        top: 3px;
        margin-right:5px;
    }
</style>